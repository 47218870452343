import { ButtonProps } from "../Button.types";

enum Colors {
  Red = "#F03E3E",
  Blue = "#4DABF7",
  Grey = "#8F9FB3",
  Black = "#0a0a0a",
  White = "#FFFFFF",
}

const colorSwitch = (color: ButtonProps["color"]) => {
  switch (color) {
    case "danger":
      return Colors.Red;
    case "primary":
      return Colors.Blue;
    default:
      return Colors.Black;
  }
};

export const backgroundColorMapper = ({ color, variant }: ButtonProps) => {
  if (variant !== "contained") return "inherit";
  return colorSwitch(color);
};

export const colorMapper = ({ color, variant }: ButtonProps) => {
  if (variant === "contained") return Colors.White;
  if (variant === "link") return color === "danger" ? Colors.Red : Colors.Black;
  return colorSwitch(color);
};

export const hoverBackgroundColorMapper = ({ color, variant }: ButtonProps) => {
  return (
    colorSwitch(color) +
    (variant === "contained" ? "85" : variant === "link" ? "00" : "")
  );
};

export const hoverColorMapper = ({ color, variant }: ButtonProps) => {
  if (variant !== "link") return Colors.White;
  return color === "danger" ? Colors.Red : Colors.Black;
};

export const borderColorMapper = ({ color, variant }: ButtonProps) => {
  if (variant !== "outline") return "none";
  return `solid 2px ${colorSwitch(color)}`;
};

export const disabledBackgroundColorMapper = (variant) => {
  if (variant === "contained") return Colors.Grey;
  return "inherit";
};

export const disabledColorMapper = (variant) => {
  if (variant === "contained") return Colors.White;
  return Colors.Grey;
};

export const disabledBorderMapper = (variant) => {
  if (variant !== "outline") return "none";
  return `solid 2px ${Colors.Grey}`;
};
