import { useEffect, useState } from "react";
import { UseCombinedLoadingState } from "./useCombinedLoadingState.types";

/**
 * A React hook that combines a collection of loading states into a single state
 * utlizing standard boolean algebra conventions.
 * @param loadingStates The array of loading states (boolean[]) to aggregate.
 * @returns The aggregate loading state.
 */
const useCombinedLoadingState: UseCombinedLoadingState = (loadingStates) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(!loadingStates.every((state) => !state));
  }, [loadingStates]);

  return { loading };
};

export default useCombinedLoadingState;
