import React from "react";
import "../typography.css";

import { BodyProps } from "./Body.types";

const variantMap: Record<BodyProps["variant"], string[]> = {
  body: ["p", "default"],
  blockquote: ["blockquote", "default"],
  validation: ["span", "text-danger"],
};

/**
 * A text component for body text and labels.
 * @param BodyProps The component props.
 * @returns A Body text component.
 */
const Body = ({ variant = "body", children }: BodyProps) =>
  React.createElement(
    variantMap[variant][0],
    { className: `${variantMap[variant][1]} typography` },
    [children]
  );

export default Body;
