import { useState, useCallback } from "react";
import { UseFormValidator, ValidationResults } from "./useFormValidator.types";
import validator from "validator";

const useFormValidator: UseFormValidator = (validationRules) => {
  const [validationResults, setValidationResults] = useState<ValidationResults>(
    () => {
      const validation = { isValid: true };

      validationRules.map((rule) => {
        validation[rule.field] = { isInvalid: false, message: "" };
      });

      return validation;
    }
  );

  const validate = useCallback(
    (formState) => {
      const validation = { isValid: true };

      validationRules.forEach((rule) => {
        const field_value = formState[rule.field].toString();

        const args = rule.args || [];

        const validation_method =
          typeof rule.method === "string"
            ? validator[rule.method]
            : rule.method;

        const isValid = validation_method(field_value, ...args, formState);

        if (isValid !== rule.validWhen) {
          validation[rule.field] = { isInvalid: true, message: rule.message };
          validation.isValid = false;
        }
      });

      setValidationResults(validation);

      return validation;
    },
    [validationRules]
  );

  return [validate, validationResults];
};

export default useFormValidator;
