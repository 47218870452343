import React from "react";
import { makeStyles } from "@material-ui/styles";

import { ButtonProps } from "./Button.types";

import {
  colorMapper,
  backgroundColorMapper,
  hoverBackgroundColorMapper,
  borderColorMapper,
  hoverColorMapper,
  disabledColorMapper,
  disabledBorderMapper,
  disabledBackgroundColorMapper,
} from "./helpers/StyleMappers";

const useStyles = makeStyles({
  buttonBase: {
    width: "auto",
    height: 40,
    padding: "6px 12px",
    fontFamily: "FuturaLTPro",
    textAlign: "center",
    fontSize: 16,
    fontStretch: "100%",
    fontWeight: 400,
    lineHeight: ({ variant }) => (variant === "outline" ? "29px" : "32px"),
    display: "inline-flex",
    alignItems: "flex-start",
    borderRadius: 2,
    border: (props: ButtonProps) => borderColorMapper(props),
    backgroundColor: (props: ButtonProps) => backgroundColorMapper(props),
    boxSizing: "border-box",
    color: (props: ButtonProps) => colorMapper(props),
    textDecoration: ({ variant }) =>
      variant === "link" ? "underline" : "none",
    "&:hover": {
      backgroundColor: (props: ButtonProps) =>
        hoverBackgroundColorMapper(props),
      cursor: "pointer",
      color: (props: ButtonProps) => hoverColorMapper(props),
      "& svg": {
        stroke: (props: ButtonProps) => hoverColorMapper(props),
      },
      textDecoration: "none",
    },
    "&:disabled": {
      backgroundColor: ({ variant }) => disabledBackgroundColorMapper(variant),
      color: ({ variant }) => disabledColorMapper(variant),
      border: ({ variant }) => disabledBorderMapper(variant),
      "&:hover": {
        opacity: 1,
        cursor: "auto",
        textDecoration: ({ variant }) =>
          variant === "link" ? "underline" : "none",
        backgroundColor: ({ variant }) =>
          disabledBackgroundColorMapper(variant),
        "& svg": {
          stroke: ({ variant }) => disabledColorMapper(variant),
        },
      },
      "& svg": {
        stroke: ({ variant }) => disabledColorMapper(variant),
      },
    },
    "&:focus": {
      boxShadow: (props: ButtonProps) =>
        `0 0 0 0.2rem  ${backgroundColorMapper(props)}50 !important`,
      outline: "initial !important",
    },
  },
  iconBase: {
    width: 22,
    height: 22,
    marginRight: ({ children }) => (children ? 8 : 0),
    marginTop: -2,
    stroke: (props: ButtonProps) => colorMapper(props),
  },
});

/**
 * A standard block styled button.
 * @param ButtonProps The component props.
 * @returns A Button component.
 */
const Button = ({
  onClick,
  variant = "contained",
  color = "primary",
  disabled = false,
  icon,
  children,
}: ButtonProps) => {
  const { buttonBase, iconBase } = useStyles({
    color,
    variant,
    children,
  } as ButtonProps);

  return (
    <button onClick={onClick} disabled={disabled} className={buttonBase}>
      {icon && <span className={iconBase}>{icon}</span>}
      {children}
    </button>
  );
};

export default Button;
